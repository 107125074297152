import React from "react"
import moment from "moment"
import DynamicComponent from "../DynamicComponent/index"
import Author from "../Author/index"
import Share from "../Share/index"
import ImagesService from '../../utils/images-service'
import SbEditable from "storyblok-react"

import "./styles.scss"
import "moment/min/locales"

import { withTranslation } from "react-i18next"

const News = ({ blok, title, date, language, slug, t }) => {
  const content =
    blok.body &&
    blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)

  moment.locale(language)
  
  return (
      <div className="news-article">
          <div className="header-image">
            <picture>
                <source media="(max-width: 450px)" srcSet={`${ ImagesService(blok.image.filename, true, { path: '450x338/smart/filters:format(webp)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '900x676/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                <source media="(max-width: 450px)" srcSet={`${ ImagesService(blok.image.filename, true, { path: '450x338/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '900x676/smart/filters:format(jpeg)'}) } 2x`} type="image/jpeg" />
                <source media="(max-width: 768px)" srcSet={`${ ImagesService(blok.image.filename, true, { path: '768x384/smart/filters:format(webp)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '1536x768/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                <source media="(max-width: 768px)" srcSet={`${ ImagesService(blok.image.filename, true, { path: '768x384/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '1536x768/smart/filters:format(jpeg)'}) } 2x`} type="image/jpeg" />
                <source media="(max-width: 1024px)" srcSet={`${ ImagesService(blok.image.filename, true, { path: '1024x512/smart/filters:format(webp)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '2048x1024/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                <source media="(max-width: 1024px)" srcSet={`${ ImagesService(blok.image.filename, true, { path: '1024x512/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '2048x1024/smart/filters:format(jpeg)'}) } 2x`} type="image/jpeg" />
                <source srcSet={`${ ImagesService(blok.image.filename, true, { path: '1440x480/smart/filters:format(webp)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '2880x960/smart/filters:format(webp)'}) } 2x`} type="image/webp" />
                <source srcSet={`${ ImagesService(blok.image.filename, true, { path: '1440x480/smart/filters:format(jpeg):quality(60)'}) } 1x, ${ ImagesService(blok.image.filename, true, { path: '2880x960/smart/filters:format(jpeg):quality(60)'}) } 2x`} type="image/jpeg" />
                <img loading="lazy" src={ImagesService(blok.image.filename, true, { path: '1440x480/smart/filters:format(jpeg):quality(60)'})} alt={blok.image.alt} width="1440" height="480" />
            </picture>
            <div className="background-image" style={{backgroundImage: 'url("' + ImagesService(blok.image.filename, true, { path: '1440x480/smart/filters:format(jpeg):quality(60)'}) + '")' }}></div>
          </div>
          <article className="news-body">
            <div className="container">
              <div className="news-header">
                <div className="h3">{blok.subtitle}</div>
                <h1>{title}</h1>
                <p>{blok.teaser}</p>
                <Author className="author" profile={blok.author.content} isLarge={false} />
                <div className="date-share">
                  <div className="publishing-date">
                    <time pubdate="pubdate" dateTime={moment(date).format('YYYY-MM-DDTHH:mm:ssZ')}>{moment(date).format('Do MMMM YYYY')}</time>
                  </div>
                  <Share link={`https://condu.co/${slug}`} headline={title} label={t("news.share.label")} title={t("news.share.title")} />
                </div>
              </div>
              <div className="news-body">
                <SbEditable content={blok}>
                  { content }
                </SbEditable>
              </div>
            </div>
          </article>
      </div>
  )
}

export default withTranslation()(News)